export default {
  data() {
    return {
      windowScrollX: window.scrollX,
      windowScrollY: window.scrollY,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.onScroll, { passive: true });
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.windowScrollX = window.scrollX;
      this.windowScrollY = window.scrollY;
    }
  }
}