<template lang="html">
  <div>
    <div class="payment__switch" v-show="show_switch">
      <div
        class="payment__option"
        :class="purchase_type == 'one_time_purchase' ? 'payment__option--selected' : ''"
        @click="purchase_type = 'one_time_purchase'"
      >
        <font-awesome-icon :icon="['fas', 'credit-card']" />
        <div class="payment__text">Single time purchase</div>
      </div>
      <div
        class="payment__option"
        :class="purchase_type == 'subscription' ? 'payment__option--selected' : ''"
        @click="purchase_type = 'subscription'"
      >
        <font-awesome-icon :icon="['fas', 'redo-alt']" />
        <div class="payment__text">Subscription</div>
      </div>
    </div>
    <div class="quantity">
      <div class="quantity-option" @click="setQuantity(4)">
        <div class="quantity-option__title">Blue Tier</div>

        <div class="quantity-option__image">
          <ImageItem :source="blue_img" />
        </div>
        <div class="quantity-option__details">1 time a week - 4 hours</div>
      </div>
      <div class="quantity-option" @click="setQuantity(8)">
        <div class="quantity-option__title">Purple Tier</div>

        <div class="quantity-option__image">
          <ImageItem :source="purple_img" />
        </div>
        <div class="quantity-option__details">2 times a week - 8 hours</div>
      </div>
      <div class="quantity-option" @click="setQuantity(12)">
        <div class="quantity-option__title">Golden Tier</div>
        <div class="quantity-option__image">
          <ImageItem :source="orange_img" />
        </div>
        <div class="quantity-option__details">3 times a week - 12 hours</div>
      </div>
    </div>
    <div class="quantity-custom">
      <div class="quantity-custom__button">
        <button class="btn btn-primary" @click="show_range = true">Custom amounth</button>
      </div>
      <div v-show="show_range" class="quantity-custom__title">
        Please select a custom number of lessons(between 1 and 50)
      </div>
      <div class="quantity-custom__input" v-show="show_range">
        <div class="quantity-custom__range">
          <div class="slidecontainer">
            <input
              type="range"
              min="1"
              max="50"
              v-model="quantity"
              class="slider"
              list="values"
            />
            <!-- <datalist id="values">
            <option value="0" label="very cold!"></option>
            <option value="25" label="cool"></option>
            <option value="50" label="medium"></option>
            <option value="75" label="getting warm!"></option>
            <option value="100" label="hot!"></option>
          </datalist> -->
          </div>
        </div>
        <div class="quantity-custom__value-container" v-show="show_range">
          <input
            class="quantity-custom__value"
            type="text"
            inputmode="numeric"
            pattern="(?:[1-9]|[1-4][0-9]|50)"
            v-model="quantity"
            min="1"
            max="50"
          />
        </div>
      </div>
      <button
        @click="setQuantity(quantity)"
        v-show="show_range"
        class="btn btn-primary quantity-custom__button-next"
      >
        {{ localizedTextFct("teacher", "select_date") }}
        <font-awesome-icon class="booking-menu__nav-icon--left" icon="arrow-right" />
      </button>
    </div>
  </div>
</template>
<script>
import ImageItem from "@/components/common/ImageItem";

export default {
  name: "CheckoutLessonQuantity",
  components: {
    ImageItem,
  },
  data() {
    return {
      purchase_type: "one_time_purchase",
      quantity: 4,
      show_range: false,
      show_switch: false,
      blue_img: process.env.VUE_APP_PUBLIC_CDN + "ai_art_fire_blue_transparent.png",
      orange_img: process.env.VUE_APP_PUBLIC_CDN + "ai_art_fire_transparent.png",
      purple_img: process.env.VUE_APP_PUBLIC_CDN + "ai_art_fire_purple_transparent.png",
    };
  },
  methods: {
    setQuantity(q) {
      this.quantity = q;
      this.$emit("element-clicked", this.quantity, this.purchase_type);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/_button.scss";

.btn {
  @include blue-button;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}

.quantity {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: $f4;
  padding: 0px 4px;
  &-option {
    background-color: $commonBgColor;
    border-radius: $gridElementBorderRadius;
    padding: $f6;

    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    border: 2px solid $courseElementBgColor;
    transition: all 100ms ease-out;

    &:hover {
      text-shadow: 1px 1px 2px white, 0 0 25px lightblue, 0 0 5px blue;
      box-shadow: 0px 0px 5px 3px blue;
      background-color: $courseElementBgColor;
      cursor: pointer;
    }

    &__title {
      font-family: $fontFamilyChat;
      font-size: $fontSizeLarge;
    }

    &__details {
      font-family: $fontFamilyVerdana;
      font-size: $fontSizeHeader;
    }

    &__image {
      width: 100px;
      height: 100px;
    }
  }

  &-custom {
    margin-top: $f5;
    justify-content: center;
    &__range {
      width: 100%;
    }
    &__button {
      text-align: center;
    }
    &__title {
      margin-top: $f5;
      font-family: $fontFamilyCommon;
    }
    &__input {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.payment {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__switch {
    display: flex;
    flex-direction: row;
    gap: $f4;
    align-items: center;
  }

  &__text {
    flex: 1;
  }

  &__option {
    flex: 1;
    font-family: $fontFamilyCommon;
    font-size: $fontSizeHeader;
    padding: 0 $f4;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    text-align: center;
    border-radius: $borderRadius;
    background-color: $courseElementBgColor;

    &:hover {
      background-color: $courseElementBgColorHover;
      cursor: pointer;
    }

    &--selected {
      background-color: $courseElementBgColorDark;
      cursor: pointer;

      &:hover {
        background-color: $courseElementBgColorDark;
        cursor: pointer;
      }
    }
  }

  &__method {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__element {
    &--margin {
      padding: 0px 8px;
      margin-top: auto;
    }
  }

  &__button {
    width: 100%;
    font-size: $fontSizeLarge;
  }
}

.slidecontainer {
  /* Width of the outside container */
  border-radius: 50%;
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  background: transparent;
  /* Full-width */
  width: 98%;
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s;
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 4 * $sliderSize;
  /* Set a specific slider handle width */
  height: 4 * $sliderSize;
  /* Slider handle height */
  background: black;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
  border-radius: 50%;
  margin-top: -$sliderSize;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 4 * $sliderSize;
  /* Set a specific slider handle width */
  height: 4 * $sliderSize;
  /* Slider handle height */
  background: black;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
  border-radius: 50%;
  margin-top: -$sliderSize;
}

.slider::-webkit-slider-runnable-track {
  background: $courseElementBgColorHover;
  height: 2 * $sliderSize;
  border-radius: $sliderSize;
}

/******** Firefox ********/
.slider::-moz-range-track {
  background: $courseElementBgColorHover;
  height: 2 * $sliderSize;
  border-radius: $sliderSize;
}

.quantity {
  position: relative;
}

input[type="text"] {
  border: none;
  /* <-- This thing here */
  border: solid 1px #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
}

input[type="text"]:invalid {
  border: red solid 3px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.quantity input {
  width: 45px;
  height: 42px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 1px solid #eee;
}

.quantity input:focus {
  outline: 0;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 42px;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  border-left: 1px solid #eee;
  width: 20px;
  text-align: center;
  color: #333;
  font-size: 13px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  line-height: 1.7;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 0;
  border-bottom: 1px solid #eee;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: -1px;
  height: 50%;
}

@media screen and (max-width: 768px) {
  .quantity {
    display: flex;
    flex-direction: column;
  }
}
</style>
