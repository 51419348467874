<template lang="html">
  <div>
    <div class="question-component" key="question">
      <div class="question" key="title">{{ current_question.question }}</div>
      <div
        class="question-wraper"
        v-show="current_question.answer_type === 'single_choice'"
      >
        <div
          class="option-container"
          :class="current_question.options.length > 5 ? 'option-container--long' : ''"
          v-for="(option, index) in current_question.options"
          :key="option + index"
          @click="$emit('select-option', option)"
        >
          <div
            class="option"
            :class="current_question.options.length > 5 ? 'option--long' : ''"
          >
            {{ option }}
          </div>
        </div>
      </div>

      <div
        class="question-wraper"
        v-show="current_question.answer_type === 'multiple_choice'"
      >
        <div
          class="option-container"
          v-for="(option, index) in current_question.options"
          :key="option + index"
        >
          <label class="mcui-checkbox option pl-2">
            <input @click="multipleChoiceSelect(option)" type="checkbox" />
            <div>
              <svg class="mcui-check" viewBox="-2 4 35 35" aria-hidden="true">
                <title>checkmark-circle</title>
                <polyline points="7.57 15.87 12.62 21.07 23.43 9.93" />
              </svg>
            </div>
            <div>{{ option }}</div>
          </label>
        </div>
      </div>

      <div v-show="current_question.answer_type === 'input'" class="question-wraper">
        <input
          :placeholder="current_question.placeholder"
          :name="current_question.input_name"
          :id="current_question.input_name"
          :autocomplete="current_question.input_name"
          v-model="user_input"
          v-validate="current_question.validation"
          type="text"
          class="option"
          :autofocus="'autofocus'"
        />
      </div>
      <div class="question-wraper" v-if="current_question?.show_checkbox">
        <div class="option-container">
          <label class="mcui-checkbox option pl-2">
            <input
              @click="privacy_policy_consent = !privacy_policy_consent"
              name="privacy policy"
              type="checkbox"
              v-validate="'required'"
            />
            <div>
              <svg class="mcui-check" viewBox="-2 4 35 35" aria-hidden="true">
                <title>checkmark-circle</title>
                <polyline points="7.57 15.87 12.62 21.07 23.43 9.93" />
              </svg>
            </div>
            <div>
              I have read and understood the
              <a
                target="_blank"
                href="https://www.freeprivacypolicy.com/live/e320fea8-fd31-4127-970d-afe6d13664c3"
                >Privacy Policy</a
              >
            </div>
          </label>
        </div>
        <div class="option-container">
          <label class="mcui-checkbox option pl-2">
            <input
              @click="promotional_email_consent = !promotional_email_consent"
              type="checkbox"
              v
            />
            <div>
              <svg class="mcui-check" viewBox="-2 4 35 35" aria-hidden="true">
                <title>checkmark-circle</title>
                <polyline points="7.57 15.87 12.62 21.07 23.43 9.93" />
              </svg>
            </div>
            <div>
              I want to receive free educational content and promotional emails in the
              future
            </div>
          </label>
        </div>
      </div>
      <transition name="fade">
        <div
          v-if="errors.has('input')"
          class="alert-danger question-wraper error-message"
          :class="{ shake: animated }"
        >
          {{ errors.first("input") }}
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="errors.has('privacy policy')"
          class="alert-danger question-wraper error-message"
          :class="{ shake: animated }"
        >
          Please check the privacy policy box
        </div>
      </transition>

      <div v-show="!hide_nav" class="nav-buttons" key="nav-buttons">
        <div class="option nav-button nav-button--left" @click="$emit('go-back')">
          <font-awesome-icon class="nav-icon" icon="arrow-left" />Go back
        </div>
        <div
          class="option nav-button nav-button--right"
          v-show="current_question.next_button === 'Skip'"
          @click="$emit('skip')"
        >
          Skip<font-awesome-icon class="nav-icon" icon="arrow-right" />
        </div>
        <div
          class="option nav-button nav-button--right"
          v-show="
            current_question.next_button === 'Next' && current_question?.last_question
          "
          @click="$emit('next')"
        >
          Finish Quiz
        </div>
        <div
          class="option nav-button nav-button--right"
          v-show="
            current_question.next_button === 'Next' && !current_question?.last_question
          "
          @click="Next"
        >
          Next<font-awesome-icon class="nav-icon" icon="arrow-right" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    current_question: {
      type: Object,
      required: true,
    },
    hide_nav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_options: [],
      user_input: "",
      animated: false,
      privacy_policy_consent: false,
      promotional_email_consent: false,
    };
  },
  methods: {
    multipleChoiceSelect(option) {
      if (this.selected_options.indexOf(option) != -1) {
        this.selected_options.splice(this.selected_options.indexOf(option), 1);
      } else {
        this.selected_options.push(option);
      }
    },
    Next() {
      let self = this;

      self.$validator.validate().then(function (isValid) {
        if (isValid) {
          if (self.current_question.answer_type === "multiple_choice") {
            self.$emit("next", self.selected_options);
          } else if (self.current_question.answer_type === "input") {
            let user_input = self.user_input;
            if (self.current_question?.show_checkbox) {
              user_input = {
                privacy_policy_consent: self.privacy_policy_consent,
                promotional_email_consent: self.promotional_email_consent,
                email: self.user_input,
              };
            }
            self.$emit("next", user_input);
          }
        } else {
          self.animated = true;
          setTimeout(() => {
            self.animated = false;
          }, 1000);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/_button.scss";

@mixin flex-wrap-fix($flex-basis, $max-viewport-width: 2000px) {
  flex-grow: 1;
  flex-basis: $flex-basis;
  max-width: 100%;

  $multiplier: 1;
  $current-width: 0px;

  @while $current-width < $max-viewport-width {
    $current-width: $current-width + $flex-basis;
    $multiplier: $multiplier + 1;

    @media (min-width: ($flex-basis * $multiplier)) {
      max-width: percentage(1 / $multiplier);
    }
  }
}

.question-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally */
  justify-content: center;
  /* Center vertically */
  background-color: #ffffff;
  padding: 20px;
}

.question {
  font-size: $fontSizeMegaLarge;
  font-family: $fontFamilyCommon;
  margin-bottom: $lap;
  text-align: center;

  &-wraper {
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: $lap;
    max-height: min(400px, 100vh - 450px);
  }
}

.option {
  cursor: pointer;
  padding: $f6;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: $f5;
  margin-bottom: $f4;
  transition: background-color 0.2s ease;
  &--long {
  }

  &-container {
    &--long {
    }
  }
}

.option:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #e0e0e0;
}

.alert-danger {
  border-radius: $f5;
  height: 100%;
  padding: $f6;
  margin-bottom: $f4;
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.3s ease, opacity 0.3s ease-in;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease, opacity 0.3s ease-out;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@media screen and (max-width: 768px) {
  .alert-container {
    flex-direction: column;
  }

  .price-container {
    margin-bottom: $f4;
  }

  .panel-container {
    margin-bottom: $f5;
  }

  .spinner-container {
    margin: $f4 0px;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.nav {
  &-buttons {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 2 * $f9;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}

body {
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;

  h1 {
    margin: 1em 0 0.25em;
  }
}

$main: #000000; //#008cff;
$grey: #ddd;
$bezier: cubic-bezier(0.65, 0.25, 0.56, 0.96);

.mcui-checkbox,
.mcui-radio {
  display: flex;
  align-items: center;
  user-select: none;
  padding: 0.6em 0;
  box-sizing: border-box;

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  input[type="checkbox"] + div,
  input[type="radio"] + div {
    border: 2px solid $grey;
    height: 1.5em;
    width: 1.5em;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
  }

  input[type="checkbox"] ~ div:last-child,
  input[type="radio"] ~ div:last-child {
    padding-left: 0.5em;
  }

  input[type="radio"] + div {
    border-radius: 50%;

    &::after {
      content: "";
      position: absolute;
      left: 25%;
      top: 25%;
      width: 50%;
      height: 50%;
      border-radius: 50%;
      transform: scale(0.5);
      opacity: 0;
      background: $main;
      transition: all 107ms $bezier;
    }
  }

  input[type="checkbox"]:focus + div,
  input[type="radio"]:focus + div,
  input[type="checkbox"]:active + div,
  input[type="radio"]:active + div {
    outline: 2px dashed $grey;
    outline-offset: 2px;
  }

  input[type="checkbox"]:checked + div {
    border-color: $main;
    transition: border-color 107ms $bezier;

    .mcui-check {
      opacity: 1;
      transition: opacity 107ms $bezier;

      polyline {
        animation: dash-check 107ms $bezier forwards;
      }
    }
  }

  input[type="radio"]:checked + div {
    border-color: $main;

    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }

  input[type="checkbox"]:indeterminate + div::after {
    content: "";
    height: 4px;
    width: 60%;
    left: 20%;
    top: calc(50% - 2px);
    position: absolute;
    background: $grey;
    border-radius: 1px;
  }

  input[type="checkbox"]:disabled ~ div,
  input[type="radio"]:disabled ~ div {
    color: $grey;
    cursor: not-allowed;
  }

  //input[type="checkbox"]:enabled ~ div,
  //input[type="radio"]:enabled ~ div {
  //cursor: default;
  //}
}

.mcui-check {
  height: 100%;
  width: 100%;
  transform: scale(1);
  color: $main;
  opacity: 0;

  polyline {
    fill: none;
    transform-origin: 50% 50%;
    stroke-width: 5px;
    stroke-dasharray: 22.771367900227325;
    stroke: currentcolor;
  }
}

@supports (display: grid) {
  .mcui-check {
    polyline {
      stroke-dashoffset: 22.771367900227325;
    }
  }
}

@keyframes dash-check {
  to {
    stroke-dashoffset: 0;
  }
}

@media screen and (max-width: 768px) {
  .question-wraper {
    column-gap: 2px;
  }

  .option {
    padding: $f5;
  }

  .question {
    font-size: $fontSizeLarge;
  }
}

@media screen and (max-height: 800px) {
  .question-wraper {
    column-gap: 2px;
  }

  .option {
    padding: $f5;
    font-size: $fontSizeSmall;
  }

  .error-message {
    font-size: $fontSizeMedium;
  }

  .question {
    font-size: $fontSizeLarge;
  }
}
</style>
