var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-container",on:{"mouseover":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[_c('div',{staticClass:"image-button-wrapper"},[_c('router-link',{attrs:{"to":{ name: _vm.linkName, params: { id: _vm.course._id } }}},[_c('div',{staticClass:"image-container"},[_c('ImageItem',{attrs:{"source":_vm.courseImg(),"size":128}})],1)]),(
        (_vm.type === 'student' && _vm.bottom_tab === 'add_remove') ||
        (_vm.type === 'student' && _vm.bottom_tab === 'progress_bar')
      )?_c('div',{staticClass:"the-border"}):_vm._e(),(_vm.type === 'student' && _vm.bottom_tab === 'add_remove')?_c('div',{staticClass:"extra-container"},[_c('div',{staticClass:"button-container",class:{ active: _vm.hovered }},[_c('span',{staticClass:"button-container__add-buttons"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.is_my_course)?_c('img',{key:"emptyPluss",staticClass:"add-button",attrs:{"src":require("@/assets/img/plus-24-white.png"),"alt":"plus sign"},on:{"click":function($event){_vm.is_my_course = !_vm.is_my_course;
                _vm.addToMyCourses();}}}):_vm._e(),(_vm.is_my_course)?_c('img',{key:"fullPluss",staticClass:"add-button",attrs:{"src":require("@/assets/img/plus-24-black.png"),"alt":"plus sign"},on:{"click":function($event){_vm.is_my_course = !_vm.is_my_course;
                _vm.removeFromMyCourses();}}}):_vm._e()])],1),_c('span',{staticClass:"button-container__add-favorite-buttons"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.is_my_fav_course)?_c('img',{key:"emptyHeart",staticClass:"favorite-button",attrs:{"src":require("@/assets/img/love-24-white.png"),"alt":"heart sign"},on:{"click":function($event){_vm.is_my_fav_course = !_vm.is_my_fav_course;
                _vm.addToMyFavoriteCourses();}}}):_vm._e(),(_vm.is_my_fav_course)?_c('img',{key:"fullHeart",staticClass:"favorite-button",attrs:{"src":require("@/assets/img/love-24-red.png"),"alt":"heart sign"},on:{"click":function($event){_vm.is_my_fav_course = !_vm.is_my_fav_course;
                _vm.removeFromMyFavoriteCourses();}}}):_vm._e()])],1)])]):_vm._e(),(_vm.type === 'student' && _vm.bottom_tab === 'progress_bar')?_c('div',{staticClass:"extra-container"},[_c('div',{staticClass:"course-progress-bar"},[_c('div',{staticClass:"course-progress-bar-filler",style:({ right: 100 - _vm.progress + '%' })}),_c('div',{staticClass:"course-progress-bar-text"},[_vm._v(" "+_vm._s(_vm.progress + "%")+" ")])])]):_vm._e()],1),_c('router-link',{attrs:{"to":{ name: _vm.linkName, params: { id: _vm.course._id } }}},[_c('div',{staticClass:"course-title"},[_vm._v(" "+_vm._s(_vm.course.title)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }