<template lang="html">
  <div class="col-md-8 central-panel central-panel--user-view">
    <div v-if="section" class="h-100">
      <div v-show="page_loaded" class="page h-100">
        <div
          class="header header--user-view text-section-title text-section-title--user-view"
        >
          {{ section.title }}
        </div>
        <div
          v-for="(section_element, index) in section.section_elements"
          class="section-element section-element--user-view"
          :key="section_element._id"
          :class="enable_click ? 'pointer' : ''"
          v-on="enable_click ? { click: () => handleClick(section_element) } : {}"
        >
          <SectionElementImage
            v-if="section_element.type == 'image'"
            :section_element="section_element"
            :course_id="course_id"
            :status="status"
          />
          <SectionElementMultiImage
            v-if="section_element.type == 'multi-image'"
            class="multi-image"
            :section_element="section_element"
            :course_id="course_id"
            :status="status"
          />
          <SectionElementText
            v-if="section_element.type == 'text'"
            :section_element="section_element"
          />
          <section-element-submit
            v-if="section_element.type == 'multi-text-variants'"
            :section_element="section_element"
            :course_id="course_id"
            :collapseId="'Text' + index"
            :submitable="false"
          >
            <template v-slot:variants="slotProps">
              <VariantButton
                :user_view="true"
                :edible="slotProps.edible"
                :choosed_answer="slotProps.choosed_answer"
                :right_answer="slotProps.right_answer"
                :index="slotProps.index"
                :locked="slotProps.locked"
              />
            </template>
            <template v-slot:items="otherSlotProps">
              <SectionElementText
                :section_element="otherSlotProps.section_element"
                :multi_text_index="otherSlotProps.multi_text_index"
              />
            </template>
          </section-element-submit>
          <section-element-submit
            v-if="section_element.type == 'multi-math-text-variants'"
            :section_element="section_element"
            :course_id="course_id"
            :collapseId="'MathText' + index"
            :submitable="false"
          >
            <template v-slot:variants="slotProps">
              <VariantButton
                :user_view="true"
                :edible="slotProps.edible"
                :choosed_answer="slotProps.choosed_answer"
                :right_answer="slotProps.right_answer"
                :index="slotProps.index"
                :locked="slotProps.locked"
              />
            </template>
            <template v-slot:items="otherSlotProps">
              <SectionElementMathText
                :section_element="otherSlotProps.section_element"
                :multi_text_index="otherSlotProps.multi_text_index"
              />
            </template>
          </section-element-submit>
          <SectionElementMultiImage
            v-if="section_element.type == 'multi-image-variants'"
            class="multi-image"
            :section_element="section_element"
            :selectable="true"
            :course_id="course_id"
            :collapseId="'Image' + index"
            :submitable="false"
          />
          <div class="section-element__text" v-if="section_element.type == 'math-text'">
            <div class="math-text-container">
              <SectionElementMathText
                :math_text="section_element.text"
                :section_element="section_element"
              />
            </div>
          </div>
          <div
            class="section-element__text"
            v-if="section_element.type == 'math-jax-text'"
          >
            <div class="math-text-container">
              <SectionElementMathJaxText
                :math_text="section_element.text"
                :section_element="section_element"
                @mathjax-loaded="loaded(index)"
              />
            </div>
          </div>
          <div class="section-element__text" v-if="section_element.type == 'code-text'">
            <div class="code-text-container">
              <SectionElementCodeText :section_element="section_element" />
            </div>
          </div>
          <div
            class="section-element__text"
            v-if="section_element.type == 'embedded-video'"
          >
            <div class="embedded-video-container">
              <SectionElementVideoEmbedded :section_element="section_element" />
            </div>
          </div>
          <div
            class="section-element__text"
            v-if="section_element.type == 'uploaded-video'"
          >
            <div class="embedded-video-container">
              <SectionElementVideoUploaded :section_element="section_element" />
            </div>
          </div>
          <div v-if="section_element.type == 'text-edible'">
            <div class="embedded-video-container">
              <editor :section_element="section_element" />
            </div>
          </div>
        </div>
        <div class="navigation-buttons">
          <button
            class="btn btn-dark add-section-button add-section-button--navigation-left"
            @click="$emit('navigate', 'previous')"
            type="button"
            name="previous"
          >
            <font-awesome-icon class="section-element-icon--left" icon="arrow-left" />
            {{ localizedTextFct("buttons", "previous_section") }}
          </button>
          <button
            class="btn btn-dark add-section-button add-section-button--navigation-right"
            @click="$emit('navigate', 'next')"
            type="button"
            name="next"
          >
            {{ localizedTextFct("buttons", "next_section") }}
            <font-awesome-icon class="section-element-icon--right" icon="arrow-right" />
          </button>
        </div>
      </div>
      <div class="spinner-container" v-show="!page_loaded">
        <ImageSpinner :width="48" :height="48" />
      </div>
    </div>
    <div v-if="!section" class="no-section">
      <div class="no-section-text">
        {{ localizedTextFct("sections", "no_section_selected") }}
      </div>
    </div>
  </div>
</template>

<script>
import SectionElementSubmit from "@/components/common/SectionElementSubmit";
import VariantButton from "@/components/common/VariantButton";

import SectionElementImage from "@/components/students/SectionElementImage";
import SectionElementMultiImage from "@/components/students/SectionElementMultiImage";
import SectionElementText from "@/components/students/SectionElementText";
import SectionElementMathText from "@/components/students/SectionElementMathText";
import SectionElementMathJaxText from "@/components/students/SectionElementMathJaxText";
import SectionElementCodeText from "@/components/students/SectionElementCodeText";
import SectionElementVideoEmbedded from "@/components/students/SectionElementVideoEmbedded.vue";
import SectionElementVideoUploaded from "@/components/students/SectionElementVideoUploaded.vue";
import Editor from "@/components/students/Editor";
import ImageSpinner from "@/components/common/ImageSpinner.vue";

export default {
  props: {
    section: {
      type: Object,
    },
    course_id: {
      type: String,
    },
    course_progress: {
      type: Object,
    },
    enable_click: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "published",
    },
  },
  components: {
    SectionElementImage,
    SectionElementMultiImage,
    SectionElementText,
    SectionElementMathText,
    SectionElementMathJaxText,
    SectionElementSubmit,
    VariantButton,
    SectionElementCodeText,
    SectionElementVideoEmbedded,
    SectionElementVideoUploaded,
    Editor,
    ImageSpinner,
  },
  data() {
    return {
      page_loaded: false,
      section_elements_loaded: [],
    };
  },
  mounted() {
    this.page_loaded = false;
    this.section_elements_loaded = [];
    if (Object.keys(this.section).length && this.section.section_elements.length !== 0) {
      for (let i = 0; i < this.section.section_elements.length; i++) {
        const section_element = this.section.section_elements[i];
        if (section_element.type === "math-jax-text") {
          this.section_elements_loaded[i] = false;
        } else {
          this.section_elements_loaded[i] = true;
        }
      }
    }
    if (this.section_elements_loaded.every(Boolean)) {
      this.page_loaded = true;
    }
  },
  methods: {
    loaded(index) {
      this.section_elements_loaded[index] = true;
      if (this.section_elements_loaded.every(Boolean)) {
        this.page_loaded = true;
      }
    },
    handleClick(section_element) {
      this.$emit("section-element-selected", section_element._id);
    },
  },
  watch: {
    section: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(newVal) {
        this.page_loaded = false;
        this.section_elements_loaded = [];

        if (
          newVal &&
          Object.keys(newVal).length &&
          this.section.section_elements.length !== 0
        ) {
          for (let i = 0; i < this.section.section_elements.length; i++) {
            const section_element = this.section.section_elements[i];
            if (section_element.type === "math-jax-text") {
              this.section_elements_loaded[i] = false;
            } else {
              this.section_elements_loaded[i] = true;
            }
          }
        }
        if (this.section_elements_loaded.every(Boolean)) {
          this.page_loaded = true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/common/CoursePanelCentral";
</style>
