<template lang="html">
  <div v-if="this.course_progress" class="container-fluid">
    <div class="row">
      <div class="col-md-4 course-image-block-container">
        <div v-if="this.course.img" class="course-image-container">
          <div class="img-container">
            <ImageItem :source="courseImg(this.course.img)" />
          </div>
          <div class="course-title">
            {{ course.title }}
          </div>
        </div>
      </div>
      <div class="col-md-4 section-names">
        <div class="section-names-header">
          {{ localizedTextFct("sections", "sections") }}
        </div>
        <div class="section-names-container">
          <div
            v-for="(section, index) in course.sections"
            class="section-name"
            :key="index"
          >
            {{ section.title }}
          </div>
        </div>
      </div>
      <div class="col-md-4 course-description-container">
        <div class="section-names-header">
          {{ localizedTextFct("sections", "description") }}
        </div>
        <div class="course-description">
          {{ course.description }}
        </div>
        <div class="overview-button-container">
          <button
            @click="startCourse"
            class="btn btn-primary overview-button"
            v-if="this.course_progress.status == 'not_started'"
            type="button"
            name="button"
            :disabled="
              (course.teacher_access_type === 'subscription' &&
                user_type === 'student' &&
                !activeSubscription) ||
              (course.teacher_access_type === 'purchase' &&
                user_type === 'student' &&
                $store.state.user_module.user_type_info.purchased_courses.indexOf(
                  course.id
                ) === -1)
            "
          >
            {{ localizedTextFct("course_overview", "start_course") }}
          </button>
          <router-link :to="{ name: 'course', params: { id: $route.params.id } }">
            <button
              class="btn btn-primary overview-button"
              v-if="this.course_progress.status == 'in_progress'"
              type="button"
              name="button"
              :disabled="
                (course.teacher_access_type === 'subscription' &&
                  user_type === 'student' &&
                  !activeSubscription) ||
                (course.teacher_access_type === 'purchase' &&
                  user_type === 'student' &&
                  $store.state.user_module.user_type_info.purchased_courses.indexOf(
                    course.id
                  ) === -1)
              "
            >
              {{ localizedTextFct("course_overview", "continue_course") }}
            </button>
          </router-link>
          <router-link :to="{ name: 'course', params: { id: $route.params.id } }">
            <button
              class="btn btn-primary overview-button"
              v-if="this.course_progress.status == 'finished'"
              type="button"
              name="button"
              :disabled="
                (course.teacher_access_type === 'subscription' &&
                  user_type === 'student' &&
                  !activeSubscription) ||
                (course.teacher_access_type === 'purchase' &&
                  user_type === 'student' &&
                  $store.state.user_module.user_type_info.purchased_courses.indexOf(
                    course.id
                  ) === -1)
              "
            >
              {{ localizedTextFct("course_overview", "view_course") }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="alert-container"
      v-if="
        course.teacher_access_type === 'subscription' &&
        user_type === 'student' &&
        !activeSubscription
      "
    >
      <div class="panel-container">
        <Panel
          :text="localizedTextFct('messages', 'subscribe_warning_course')"
          type="caution"
        />
      </div>
      <div class="router-container">
        <router-link :to="{ name: 'settings', params: { option: 'subscriptions' } }">
          <button type="button" name="button" class="btn btn-primary h-100 w-100">
            {{ localizedTextFct("course_overview", "subscribe") }}
          </button>
        </router-link>
      </div>
    </div>
    <div
      class="alert-container"
      v-if="
        course.teacher_access_type === 'purchase' &&
        user_type === 'student' &&
        $store.state.user_module.user_type_info.purchased_courses.indexOf(course.id) ===
          -1
      "
    >
      <div class="panel-container">
        <Panel :text="localizedTextFct('messages', 'course_not_free')" type="caution" />
      </div>
      <div class="price-container">
        <div class="">
          {{ "Price:" }}
          <span class="price-tag">
            {{ " $" + course.price }}
          </span>
        </div>
      </div>
      <div class="router-container">
        <button
          type="button"
          name="button"
          class="btn btn-primary h-100 w-100"
          @click="purchaseCourse"
          :disabled="loading"
          :aria-disabled="loading"
        >
          {{ localizedTextFct("course_overview", "buy_course") }}
        </button>
        <div class="spinner-container" v-show="loading">
          <ImageSpinner />
        </div>
      </div>
    </div>
    <div
      class="alert-container"
      :class="{ shake: animated }"
      v-if="
        course.teacher_access_type === 'purchase' &&
        user_type === 'student' &&
        !$store.state.user_module.user.default_card_id &&
        $store.state.user_module.user_type_info.purchased_courses.indexOf(course.id) ===
          -1
      "
    >
      <div class="panel-container panel-container--big">
        <Panel :text="localizedTextFct('messages', 'add_card_warning')" type="caution" />
      </div>
      <div class="router-container">
        <router-link :to="{ name: 'settings', params: { option: 'add-card' } }">
          <button type="button" name="button" class="btn btn-primary h-100 w-100">
            {{ localizedTextFct("course_overview", "add_card") }}
          </button>
        </router-link>
      </div>
    </div>
    <div class="message__container" ref="message_box">
      <MessageBox :message_type="message_type" :messages="messages" />
    </div>
  </div>
</template>

<script>
import Course from "@/models/course";
import CustomError from "@/models/custom_error";
import SharedDataService from "@/services/SharedDataService.js";
import StudentDataService from "@/services/StudentDataService.js";

import ImageItem from "@/components/common/ImageItem";
import Panel from "@/components/common/Panel";
import ImageSpinner from "@/components/common/ImageSpinner";

import MessageBox from "@/components/common/MessageBox.vue";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

import money from "money-math";

export default {
  name: "CourseOverview",
  metaInfo() {
    return {
      title: this.course.title ? this.course.title : "Course Overview",
    };
  },
  components: {
    ImageItem,
    Panel,
    ImageSpinner,

    MessageBox,
  },
  mixins: [handleServerMessage],
  data() {
    return {
      course: new Course("", "", [], -1),
      user_type: JSON.parse(localStorage.getItem("user_type")),
      course_progress: null,
      current_section: null,
      current_index: null,
      loading: false,
      payments: {},
      messages: [],
      message_type: [],
      animated: false,
    };
  },
  async mounted() {
    try {
      this.payments = window.Square.payments(
        process.env.VUE_APP_SQUARE_APP_ID,
        process.env.VUE_APP_SQUARE_LOCATION_ID
      );
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async verifyBuyer(sourceId) {
      const verificationDetails = {
        amount: money.floatToAmount(this.course.price),
        billingContact: this.$store.state.user_module.user.billing_info,
        currencyCode: "USD",
        intent: "CHARGE",
      };

      const verificationResults = await this.payments.verifyBuyer(
        sourceId,
        verificationDetails
      );

      return verificationResults.token;
    },
    async purchaseCourse() {
      try {
        this.loading = true;
        if (!this.$store.state.user_module.user.default_card_id) {
          throw new CustomError("error", "No card on file");
        }
        let verificationToken = await this.verifyBuyer(
          this.$store.state.user_module.user.default_card_id
        );
        let res = await this.$store.dispatch("user_module/purchaseCourse", {
          course_id: this.course.id,
          verificationToken: verificationToken,
        });
        this.handleServerMessage(res, "res", 3000);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        if (
          e.message == "No card on file" ||
          (e.errors && e.errors[0] && e.errors[0].code === "NOT_FOUND")
        ) {
          this.animated = true;
          const self = this;
          setTimeout(() => {
            self.animated = false;
          }, 1000);
        }

        this.handleServerMessage(e, "err", 5000);
        this.loading = false;
      }
    },
    getCourse(id = null) {
      let course_id;
      if (id) {
        course_id = id;
      } else {
        course_id = this.$route.params.id;
      }
      SharedDataService.getCourse(course_id)
        .then((res) => {
          this.course = res.data;
          this.$Progress.finish();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCourseProgress() {
      StudentDataService.getCourseProgress(this.$route.params.id)
        .then((res) => {
          this.course_progress = res.data.course_progress;
          this.$Progress.finish();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    startCourse() {
      StudentDataService.startCourse(this.$route.params.id)
        .then((res) => {
          this.course_progress = res.data.course_progress;
          this.$router.push({ name: "course", params: { id: this.$route.params.id } });
          this.$Progress.finish();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    courseImg(img) {
      if (img) {
        return process.env.VUE_APP_API_URL + "img/courses/" + this.course.id + "/" + img;
      }
    },
  },
  created() {
    this.getCourse();
    this.getCourseProgress();
  },
  computed: {
    activeSubscription() {
      return this.$store.state.user_module.user.subscription_details
        ? this.$store.state.user_module.user.subscription_details.active
        : false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/common/CourseOverview.scss";

.spinner-container {
  position: absolute;
}

.alert-container {
  display: flex;
  margin-top: $lap;
  column-gap: 30px;
}

.panel-container {
  flex: 0 0 calc(2 * (100% + 30px) / 3 - 30px);
  // margin-right: $lap;
  & > .panel {
    height: 100%;
  }
  &--big {
    flex: 0 0 calc(2 * (100% + 30px) / 3 - 30px);
  }
}

.router-container {
  flex: auto;
  position: relative;
}

.spinner-container {
  position: absolute;
  right: 2px;
  bottom: 2px;
}

.price-container {
  // margin: 0px 30px;
  background: $commonBgColor;
  border-radius: $borderRadius;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  flex: auto;
}

.price-tag {
  color: #007bff;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@media screen and (max-width: 768px) {
  .alert-container {
    flex-direction: column;
  }

  .price-container {
    margin-bottom: $f4;
  }

  .panel-container {
    margin-bottom: $f5;
  }

  .spinner-container {
    margin: $f4 0px;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
