<template>
  <div class="text-section-element text-section-element--user-view">
    <div class="text-section-title">
      {{ section_element.meta.title }}
    </div>
    <editor-content :editor="editor" class="someclass" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
//extensions
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";

export default {
  components: {
    EditorContent,
  },

  props: {
    section_element: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  mounted() {
    this.editor = new Editor({
      editable: false,
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          HTMLAttributes: {
            class: "tiptap-link pointer",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        }),
      ],
      content: this.section_element.text,
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
@import "@/css/common/TextSectionElement.scss";

.pointer {
  cursor: pointer;
}
</style>
