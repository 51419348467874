<template>
  <div>
    <div class="page" ref="page1">
      <transition name="fade" mode="out-in" appear>
        <div class="marketing-message-title" :key="current_title">
          {{ current_title }}
        </div>
      </transition>
      <div class="logo-container">
        <img
          class="img-fluid mx-auto d-block mt-4 logo-img"
          src="@/assets/img/Cute_fire_small_size.png"
          alt="cute fire"
        />
      </div>
      <transition name="fade" mode="out-in" appear>
        <QuestionTab
          v-if="show_quiz"
          :key="current_question_index"
          :current_question="current_question"
          @select-option="selectOption"
          @go-back="goBack"
          @skip="Skip"
          @next="Next"
        />
      </transition>
      <!-- Calendly inline widget begin -->
      <vue-calendly
        v-if="!show_quiz"
        :url="
          'https://calendly.com/olimprometheus?hide_landing_page_details=1&hide_gdpr_banner=1&primary_color=d3d5f5' +
          '&' +
          'name=' +
          this.name +
          '&' +
          'email=' +
          this.email
        "
        :height="400"
        :width="320"
      ></vue-calendly>
      <!-- Calendly inline widget end -->

      <div class="option nav-button" @click="scrollTo('page2')">
        Learn More<font-awesome-icon class="nav-icon" icon="arrow-down" />
      </div>
    </div>
    <div class="page page--full container-fluid" ref="page2">
      <div class="marketing-message-title">What do we offer?</div>
      <div class="variants row">
        <div class="variant col-md-6">
          <div class="group-lessons">
            <div class="variant__title">Group Lessons</div>
            <div class="variant__image-container">
              <img
                class="variant__image"
                src="@/assets/img/group lessons.jpg"
                alt="Group lessons"
              />
            </div>
          </div>
          <div class="benefits">
            <div class="benefits__title">Benefits of Group Lessons</div>
            <div class="accordion" id="accordion1">
              <div class="benefit" v-for="(el, i) in group_benefits" :key="i">
                <div
                  id="heading1"
                  class="benefit__title"
                  data-toggle="collapse"
                  :data-target="'#collapse' + i"
                  aria-expanded="true"
                  :aria-controls="'#collapse' + i"
                >
                  <img class="benefit__icon" :src="el.asset" :alt="el.alt" />
                  <div class="benefit__text">{{ el.title }}</div>
                </div>

                <div
                  :id="'collapse' + i"
                  class="collapse"
                  :aria-labelledby="'heading' + i"
                  data-parent="#accordion1"
                >
                  <div class="benefit__content">
                    {{ el.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="variant col-md-6">
          <div class="variant__title">One on one lessons</div>
          <div class="variant__image-container">
            <img
              class="variant__image"
              src="@/assets/img/online lesson 2.png"
              alt="Group lessons"
            />
          </div>
          <div class="benefits">
            <div class="benefits__title">Benefits of One-on-One Lessons</div>
            <div class="accordion" id="accordion2">
              <div class="benefit" v-for="(el, i) in lesson_benefits" :key="i">
                <div
                  id="heading1"
                  class="benefit__title"
                  data-toggle="collapse"
                  :data-target="'#collapse2' + i"
                  aria-expanded="true"
                  :aria-controls="'#collapse2' + i"
                >
                  <img class="benefit__icon" :src="el.asset" :alt="el.alt" />
                  <div class="benefit__text">{{ el.title }}</div>
                </div>

                <div
                  :id="'collapse2' + i"
                  class="collapse"
                  :aria-labelledby="'heading2' + i"
                  data-parent="#accordion2"
                >
                  <div class="benefit__content">
                    {{ el.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="option nav-button" @click="scrollTo('page3')">
        Learn More<font-awesome-icon class="nav-icon" icon="arrow-down" />
      </div>
    </div>
    <div class="page page--full" ref="page3"></div>

    <transition name="fade" mode="out-in" appear>
      <div
        v-show="windowScrollY > 800"
        @click="scrollTo('top')"
        class="option option--sticky"
      >
        <font-awesome-icon class="nav-icon" icon="arrow-up" />
      </div>
    </transition>
  </div>
</template>

<script>
//import services
import AuthService from "@/services/AuthService.js";

import QuestionTab from "@/components/common/QuestionTab.vue";

//import mixins
import windowScroll from "@/mixins/windowScroll.js";

export default {
  components: {
    QuestionTab,
  },
  mixins: [windowScroll],

  props: {
    questions: {
      type: Array,
      default: () => [
        {
          question: "What do you want to learn?",
          question_index: 0,
          first_question: true,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["Math", "English", "Spanish", "Other subject"],
          next_question_index: [1, 2, 2, 4],
        },
        {
          question: "Are you a parent or a student?",
          question_index: 1,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["Parent", "Student", "Student(18+)"],
          next_question_index: [10, 2, 2],
        },
        {
          question: "What is your current level?",
          question_index: 2,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "Preschool",
            "Kindergarten",
            "Elementary School (grades 1 - 5)",
            "Middle School (grades 6 - 8)",
            "High School (grades 9 - 12)",
            "College/University",
            "Homeschooling",
            "Self-Learner",
          ],
          next_question_index: [3, 3, 3, 3, 4, 5, 4, 5],
        },
        {
          question: "What subject do you need help with?",
          question_index: 3,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["Algebra", "Geometry", "Math in general", "Other"],
          next_question_index: [6, 6, 6, 6],
        },
        {
          question: "What subject do you need help with?",
          question_index: 4,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "Algebra 1",
            "Geometry",
            "Algebra 2",
            "Trigonometry",
            "Pre - Algebra",
            "Pre - Calculus",
            "Calculus",
            "SAT Test Prep",
            "ACT Test Prep",
            "Statistics",
            "Math in general",
            "Other",
          ],
          next_question_index: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        },
        {
          question: "What subject do you need help with?",
          question_index: 5,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "College Algebra",
            "Calculus",
            "Pre - Calculus",
            "Statistics",
            "Discrete Math",
            "Probability Theory",
            "Differential Equations",
            "Linear Algebra",
            "Group Theory",
            "Combinatorics",
            "Math in general",
            "Other",
          ],
          next_question_index: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        },
        {
          question: "What is your goal?",
          question_index: 6,
          answer_type: "multiple_choice",
          next_button: "Next",
          options: [
            "I need help with a current class",
            "I just need to review previous material",
            "I am preparing for a test",
            "I want to learn something new",
            "Other",
          ],
          next_question_index: [7],
        },
        {
          question: "What is your first name?*",
          question_index: 7,
          answer_type: "input",
          placeholder: "Please enter your first name",
          validation: "required|alpha_spaces|max:30",
          next_button: "Next",
          next_question_index: [8],
        },
        {
          question: "What is your email address?*",
          question_index: 8,
          answer_type: "input",
          show_checkbox: true,
          placeholder: "Please enter your email address",
          validation: "required|email|max:45",
          next_button: "Next",
          next_question_index: [9],
        },
        {
          question: "What is your phone number?",
          question_index: 9,
          last_question: true,
          placeholder: "(888) 888-8888",
          validation: {
            regex: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
          },
          answer_type: "input",
          next_button: "Next",
          next_question_index: [10],
        },
        {
          question: "What is your child's current educational level?",
          question_index: 10,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "Preschool",
            "Kindergarten",
            "Elementary School (grades 1 - 5)",
            "Middle School (grades 6 - 8)",
            "High School (grades 9 - 12)",
            "College/University",
            "Homeschooling",
            "Self-Learner",
          ],
          next_question_index: [11, 11, 11, 11, 12, 13, 12, 13],
        },
        {
          question: "What subject does your child needs help with?",
          question_index: 11,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["Algebra", "Geometry", "Math in general", "Other"],
          next_question_index: [14, 14, 14, 14],
        },
        {
          question: "What subject does your child needs help with?",
          question_index: 12,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "Algebra 1",
            "Geometry",
            "Algebra 2",
            "Trigonometry",
            "Pre - Algebra",
            "Pre - Calculus",
            "Calculus",
            "SAT Test Prep",
            "ACT Test Prep",
            "Statistics",
            "Math in general",
            "Other",
          ],
          next_question_index: [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14],
        },
        {
          question: "What subject does your child needs help with?",
          question_index: 13,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "College Algebra",
            "Calculus",
            "Pre - Calculus",
            "Statistics",
            "Discrete Math",
            "Probability Theory",
            "Differential Equations",
            "Linear Algebra",
            "Group Theory",
            "Combinatorics",
            "Math in general",
            "Other",
          ],
          next_question_index: [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14],
        },
        {
          question: "Why are you seeking help?",
          question_index: 14,
          answer_type: "multiple_choice",
          next_button: "Next",
          options: [
            "My child needs help with a current class",
            "My child needs to review previous material",
            "My child needs help for an upcoming test",
            "My child wants to learn something new",
            "Other",
          ],
          next_question_index: [15],
        },
        {
          question: "What is your first name?*",
          question_index: 15,
          answer_type: "input",
          placeholder: "Please enter your first name",
          validation: "required|alpha_spaces|max:30",
          next_button: "Next",
          next_question_index: [16],
        },
        {
          question: "What is your email address?*",
          question_index: 16,
          answer_type: "input",
          show_checkbox: true,
          placeholder: "Please enter your email address",
          validation: "required|email|max:45",
          next_button: "Next",
          next_question_index: [17],
        },
        {
          question: "What is your phone number?",
          question_index: 17,
          last_question: true,
          placeholder: "(888) 888-8888",
          validation: {
            regex: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
          },
          answer_type: "input",
          next_button: "Next",
          next_question_index: [10],
        },
        // Add more questions as needed
      ],
    }, // Array of question objects (each with a question and options)
  },
  data() {
    return {
      current_question_index: 0,
      selected_questions: [0],
      user_input: {},
      current_title: "Welcome to Promi!",
      privacy_policy_consent: false,
      promotional_email_consent: false,

      name: "Han",
      email: "email@email",
      phone: "",

      show_quiz: true,

      group_benefits: [
        {
          alt: "one",
          asset: require("@/assets/img/one.svg"),
          title: "Social Interaction",
          content:
            "Opportunities to learn from peers and collaborate in a group setting.",
        },
        {
          alt: "two",
          asset: require("@/assets/img/two.svg"),
          title: "Teamwork Skills",
          content: "Develops collaboration, communication, and leadership abilities.",
        },
        {
          alt: "three",
          asset: require("@/assets/img/three.svg"),
          title: "Cost-Effective",
          content: "Much more affordable than 1 on 1 lesson.",
        },
        {
          alt: "four",
          asset: require("@/assets/img/four.svg"),
          title: "Peer Support",
          content: "Our students help each other.",
        },
        {
          alt: "five",
          asset: require("@/assets/img/five.svg"),
          title: "Fun and Enjoyment",
          content: "Learn while making new friends!",
        },
      ],
      lesson_benefits: [
        {
          alt: "one",
          asset: require("@/assets/img/one.svg"),
          title: "Personalized Attention",
          content: "Tailored lessons cater to individual learning styles and needs.",
        },
        {
          alt: "two",
          asset: require("@/assets/img/two.svg"),
          title: "Customized Pace",
          content:
            "Learners progress at their own speed without being rushed or held back.",
        },
        {
          alt: "three",
          asset: require("@/assets/img/three.svg"),
          title: "Immediate Feedback",
          content:
            "Prompt correction of mistakes and reinforcement of correct practices.",
        },
        {
          alt: "four",
          asset: require("@/assets/img/four.svg"),
          title: "Increased Engagement",
          content:
            "Higher interaction levels encourage active participation and deeper understanding.",
        },
        {
          alt: "five",
          asset: require("@/assets/img/five.svg"),
          title: "Builds Confidence",
          content: "Supportive environment boosts confidence and willingness to learn.",
        },
      ],
    };
  },
  mounted() {},
  computed: {
    current_question() {
      return this.questions[this.current_question_index];
    },
  },
  methods: {
    scrollTo(refId) {
      if (refId === "top") {
        return window.scrollTo({ top: 0, behavior: "smooth" });
      }
      const el = this.$refs[refId];

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    goBack() {
      if (this.current_question_index === 0) {
        return;
      }
      let key = this.selected_questions.pop();
      delete this.user_input[key];
      this.current_question_index = this.selected_questions[
        this.selected_questions.length - 1
      ];
    },
    async Skip() {
      this.user_input[this.current_question_index] = "";
      this.current_question_index = this.questions[
        this.current_question_index
      ].next_question_index[0];
      this.selected_questions.push(this.current_question_index);
    },
    async Next(input) {
      try {
        if (this.questions[this.current_question_index]?.last_question) {
          this.user_input[this.current_question_index] = input;
          let quiz_answers = [];
          for (const key in this.user_input) {
            if (Object.hasOwnProperty.call(this.user_input, key)) {
              const element = this.user_input[key];
              if (key == 8 || key == 16) {
                this.email = element;
              } else if (key == 7 || key == 15) {
                this.name = element;
              } else if (key == 9 || key == 17) {
                this.phone = element;
              } else {
                quiz_answers.push({
                  question: this.questions[key].question,
                  ...(Array.isArray(element) && {
                    answers: element,
                    question_type: "multi_choice",
                  }),
                  ...(!Array.isArray(element) && {
                    answer: element,
                    question_type: "single_choice",
                  }),
                });
              }
            }
          }
          console.log(quiz_answers);
          let res = await AuthService.saveCustomerAnswers(
            this.name,
            this.email,
            this.phone,
            this.privacy_policy_consent,
            this.promotional_email_consent,
            quiz_answers
          );
          console.log(res);
          this.show_quiz = false;
          this.current_title = "Congrats! You've unlocked a free lesson!";
        } else if (this.questions[this.current_question_index]?.show_checkbox) {
          this.user_input[this.current_question_index] = input.email;
          this.privacy_policy_consent = input.privacy_policy_consent;
          this.promotional_email_consent = input.promotional_email_consent;
          this.current_question_index = this.questions[
            this.current_question_index
          ].next_question_index[0];
          this.selected_questions.push(this.current_question_index);
        } else {
          this.user_input[this.current_question_index] = input;
          this.current_question_index = this.questions[
            this.current_question_index
          ].next_question_index[0];
          this.selected_questions.push(this.current_question_index);
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectOption(option) {
      // Save the selected answer (you can handle this in the parent component)
      this.user_input[this.current_question_index] = option;

      // Move to the next question (loop back to the first question if at the end)
      if (this.current_question_index === this.questions.length) {
        this.$emit("form-completed");
      }

      this.current_question_index = this.questions[
        this.current_question_index
      ].next_question_index[
        this.questions[this.current_question_index].options.indexOf(option)
      ];
      this.selected_questions.push(this.current_question_index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/_button.scss";

.point {
  text-align: center;
}

.page {
  height: calc(100vh - 56px);
  position: relative;
  &--full {
    height: 100vh;
  }
}

.variant__title {
  text-align: center;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  font-size: $fontSizeMegaLarge;
}

.variant__image {
  &-container {
    text-align: center;
  }
  max-width: 400px;
}

.variants {
}

.variant {
}

.benefits {
  text-align: center;
  padding: 4px;
  &__title {
    font-size: $fontSizeExtraLarge;
    margin-bottom: $lap;
  }
}

.benefit {
  &__icon {
    width: 3 * $lap;
    height: 3 * $lap;
    filter: invert(6%) sepia(64%) saturate(28%) hue-rotate(314deg) brightness(109%)
      contrast(88%);
  }

  &__text {
    margin-left: auto;
    margin-right: auto;
    padding-right: 3 * $lap;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $fontSizeLarge;
    border: 1px solid #ddd;
    border-radius: $f5;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &__content {
    padding: $f4;
  }
}

#calendryWidget {
  border-radius: $gridElementBorderRadius;
}

.logo-img {
  display: block;
  max-width: 200px;
  max-height: 100px;
  width: auto;
  height: auto;
}

.marketing-message-title {
  font-size: 2 * $fontSizeMegaLarge;
  font-family: $fontFamilyChat;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .marketing-message-title {
  }
}

.question-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally */
  justify-content: center;
  /* Center vertically */
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  height: 100%;
}

.question {
  font-size: $fontSizeMegaLarge;
  font-family: $fontFamilyCommon;
  margin-bottom: $lap;
}

.option {
  cursor: pointer;
  padding: $f6;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: $f5;
  margin-bottom: $f4;
  transition: background-color 0.2s ease;

  &--sticky {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
    right: 0;
    margin-left: auto;
    margin-right: $f6;
    margin-bottom: $f6;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.option:hover {
  background-color: #f0f0f0;
}

.nav {
  &-button {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: $f6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 2 * $f9;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.selected {
  background-color: #e0e0e0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
