<template lang="html">
  <div class="container-fluid my-3">
    <div class="row">
      <div class="col-md-2 user-info">
        <ProfilePanelLeft :user="user" @show-grid="showGrid" />
      </div>
      <div class="col-md-3 order-md-3">
        <ProfilePanelRight :user="user" @schedule-lesson="show_booking_menu = true" />
      </div>
      <div class="col-md-7 order-md-2">
        <ProfileAboutTeacher
          :user="user"
          v-if="this.current_grid == 'about'"
          @element-clicked="showCheckout"
        />
        <UserInfoCourseGrid
          v-if="this.current_grid == 'my_courses'"
          :courses="user.my_courses"
          :grid_title="localizedTextFct('grid_titles', 'teacher_courses')"
          custom_class_style="col-md-3 col-sm-6"
          course_list_name="my_courses"
        />
        <!-- <UserInfoUserGrid
          v-if="this.current_grid == 'my_students'"
          :users="user.my_students"
          list_name="my_students"
          grid_content="my_students"
          user_type="student"
          custom_class_style="col-md-3 col-sm-6"
          :grid_title="localizedTextFct('grid_titles', 'teacher_students')"
          course_list_name="my_students"
          :show_buttons="false"
        /> -->
      </div>
    </div>
    <transition name="menu">
      <div class="booking-menu" v-if="show_booking_menu" @click.self="close">
        <div class="booking-menu__content">
          <div class="booking-menu__elements">
            <!-- <transition-group
              tag="div"
              class="element__slider"
              :name="back ? 'slideback' : 'slide'"
            >
              <div class="element" v-show="menuElementIndex === 0" key="1">
                <div class="element__title">Please choose the time for the lesson</div>
                <div class="element__content">
                  <FullCalendarScheduleTime
                    :user="this.user_id"
                    :mini="true"
                    @element-clicked="menuNext"
                  />
                </div>
              </div>
              <div class="element" v-show="menuElementIndex === 0" key="2">
                <CheckoutLesson :user="this.user" :date="selected_date" />
              </div>
            </transition-group> -->
            <div class="element">
              <div
                class="element__title"
                v-show="this.currentTabComponent == 'CheckoutLesson'"
              >
                {{ localizedTextFct("teacher", "checkout") }}
              </div>
              <div
                class="element__title"
                v-show="this.currentTabComponent == 'CheckoutLessonQuantity'"
              >
                {{ localizedTextFct("teacher", "choose_quantity") }}
              </div>
              <div
                class="element__title"
                v-show="this.currentTabComponent == 'FullCalendarScheduleTime'"
              >
                {{ localizedTextFct("teacher", "choose_time") }}
              </div>
              <div class="element__content">
                <!-- <keep-alive> -->
                <component
                  :is="currentTabComponent"
                  v-bind="currentProperties"
                  @element-clicked="menuNext"
                  @changed="changed"
                ></component>
                <!-- </keep-alive> -->
              </div>
            </div>
          </div>

          <div class="booking-menu__nav-buttons">
            <button
              class="booking-menu__nav-button booking-menu__nav-button--left btn btn-primary"
              @click="prev"
              v-show="
                currentTabComponent == 'CheckoutLesson' ||
                currentTabComponent == 'FullCalendarScheduleTime'
              "
            >
              <font-awesome-icon class="booking-menu__nav-icon--left" icon="arrow-left" />
              <div class="booking-menu__nav-text">
                {{
                  localizedTextFct(
                    "teacher",
                    currentTabComponent == "FullCalendarScheduleTime"
                      ? "select_quantity"
                      : "select_date"
                  )
                }}
              </div>
            </button>
            <button
              class="booking-menu__nav-button booking-menu__nav-button--right btn btn-primary"
              @click="next"
              v-show="
                currentTabComponent == 'CheckoutLessonQuantity' ||
                currentTabComponent == 'FullCalendarScheduleTime'
              "
              :disabled="!this.selected_date"
            >
              <div class="booking-menu__nav-text">
                {{
                  localizedTextFct(
                    "teacher",
                    currentTabComponent == "FullCalendarScheduleTime"
                      ? "checkout"
                      : "select_date"
                  )
                }}
              </div>
              <font-awesome-icon
                class="booking-menu__nav-icon--right"
                icon="arrow-right"
              />
            </button>
          </div>
        </div>
      </div>
    </transition>
    <div class="floating-button">
      <div @click="show_booking_menu = true" class="button button--large">
        <font-awesome-icon class="button__icon" :icon="['far', 'calendar-check']" />
        <div class="button__text">
          {{ localizedTextFct("profile", "schedule_lesson") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePanelLeft from "@/components/common/ProfilePanelLeft";
import ProfilePanelRight from "@/components/common/ProfilePanelRight";
import ProfileAboutTeacher from "@/components/common/ProfileAboutTeacher";
import FullCalendarScheduleTime from "@/components/common/FullCalendarScheduleTime.vue";
import CheckoutLesson from "@/components/common/CheckoutLesson.vue";
import CheckoutLessonQuantity from "@/components/common/CheckoutLessonQuantity.vue";

import UserInfoCourseGrid from "@/components/common/UserInfoCourseGrid";
import UserInfoUserGrid from "@/components/common/UserInfoUserGrid";
import SharedDataService from "@/services/SharedDataService.js";

export default {
  name: "teacher_profile",
  metaInfo: {
    title: "Teacher profile",
  },
  components: {
    ProfilePanelLeft,
    ProfilePanelRight,
    ProfileAboutTeacher,
    FullCalendarScheduleTime,
    CheckoutLesson,
    CheckoutLessonQuantity,

    UserInfoCourseGrid,
    UserInfoUserGrid,
  },
  data() {
    return {
      user: {
        _id: "",
        username: "",
        img: "",
        user_type: "",
        first_name: "",
        second_name: "",
        my_students: [],
        my_courses: [],
      },
      loaded: {
        about: true,
        my_students: false,
        my_courses: false,
      },
      current_grid: "",
      show_booking_menu: false,
      selected_date: "",
      back: false,
      menuElementIndex: 0,
      currentTabComponent: "CheckoutLessonQuantity",
    };
  },
  methods: {
    next() {
      this.back = false;
      this.menuElementIndex++;
      if (this.currentTabComponent == "CheckoutLessonQuantity") {
        this.currentTabComponent = "FullCalendarScheduleTime";
      } else if (this.currentTabComponent == "FullCalendarScheduleTime") {
        this.currentTabComponent = "CheckoutLesson";
      }
    },
    prev() {
      this.back = true;
      this.menuElementIndex--;
      if (this.currentTabComponent == "CheckoutLesson") {
        this.currentTabComponent = "FullCalendarScheduleTime";
      } else if (this.currentTabComponent == "FullCalendarScheduleTime") {
        this.currentTabComponent = "CheckoutLessonQuantity";
      }
    },
    close() {
      this.back = false;
      this.menuElementIndex = 0;
      this.currentTabComponent = "CheckoutLessonQuantity";
      this.selected_date = "";
      this.show_booking_menu = false;
    },
    async getUser() {
      try {
        let res = await SharedDataService.getProfileInfo(this.$route.params.id);
        this.user = res.data.user;
        this.showGrid("about");
        this.$Progress.finish();
      } catch (e) {
        console.log(e);
      }
    },
    async showGrid(grid_name) {
      if (this.loaded[grid_name]) {
        this.current_grid = grid_name;
      } else {
        try {
          let res = await SharedDataService.getTeacherInfo(
            grid_name,
            this.$route.params.id
          );
          let old_user = this.user;
          old_user[grid_name] = res.data[grid_name];
          this.user = old_user;
          this.loaded[grid_name] = true;
          this.current_grid = grid_name;
        } catch (e) {
          console.log(e);
        }
      }
    },
    showCheckout(date) {
      console.log(date);
      this.show_booking_menu = true;
      this.selected_date = date;
    },
    menuNext(value, value2) {
      console.log(value, value2);
      this.show_booking_menu = true;
      this.menuElementIndex += 1;
      if (this.currentTabComponent == "CheckoutLessonQuantity") {
        this.quantity = Number(value);
        this.purchase_type = value2;
        if (this.selected_date) {
          this.menuElementIndex += 1;
          this.currentTabComponent = "CheckoutLesson";
          return;
        }
        this.currentTabComponent = "FullCalendarScheduleTime";
      } else if (this.currentTabComponent == "FullCalendarScheduleTime") {
        this.selected_date = value;
        this.currentTabComponent = "CheckoutLesson";
      }
    },
    changed(e) {
      console.log(e.target.value);
      this.quantity = e.target.value;
    },
  },
  created() {
    this.getUser();
  },
  computed: {
    currentProperties() {
      let user = this.user;
      if (this.currentTabComponent == "FullCalendarScheduleTime") {
        return { mini: true, user_id: user.user };
      } else if (this.currentTabComponent == "CheckoutLesson") {
        return { user: user, date: this.selected_date, quantity: this.quantity };
      } else if (this.currentTabComponent == "CheckoutLessonQuantity") {
        return { user: user };
      } else {
        return { user: user };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/_button.scss";
@import "@/css/variables.scss";

.floating-button {
  position: sticky;
  bottom: 0;
  z-index: 2;
  display: none;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;
  font-size: $fontSizeHeader;
  border-radius: $borderRadius;
  background-color: $commonBgColor;
  margin-bottom: $f4;

  &:hover {
    background-color: $courseElementBgColor;
    cursor: pointer;
  }

  &:active {
    background-color: $courseElementBgColorHover;
  }

  &__icon {
    height: $buttonIconHeight;
    width: $buttonIconHeight;
    font-size: $buttonIconHeight;
    margin-left: $f4;
  }

  &__text {
    flex: 1;
    text-align: center;
  }

  &--large {
    background-color: $courseElementBgColorHover;

    &:hover {
      background-color: $courseElementBgColorDark;
      cursor: pointer;
    }

    &:active {
      background-color: $courseElementBgColorDark;
    }
    font-weight: 500;
    border: 2px solid black;
  }
}

.btn {
  @include blue-button;
  font-size: 12px;

  &--danger {
    @include red-button;
  }
}
.booking-menu {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  padding: 5% auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  overflow-y: scroll;
  transition: opacity 2s ease-in-out;
  &__content {
    border-radius: $borderRadius;
    padding: $lap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $commonBgColor;
    margin-top: 10vh;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  &__nav-buttons {
    margin-top: 2 * $lap;
    display: flex;
    flex-direction: row;
    gap: 2%;
  }
  &__nav-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    gap: $f4;
    &--left {
      margin-right: auto;
    }
    &--right {
      margin-left: auto;
    }
  }
  &__nav-text {
    flex: 1;
    text-align: center;
  }
  &__elements {
    overflow: hidden;
  }
}

.element {
  min-width: calc(800px - 2 * $lap);
  &__slider {
    gap: 0;
    display: flex;

    flex-direction: row;
  }
  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .booking-menu__content {
    width: 96%;
  }

  .user-info {
    margin-bottom: $lap;
  }

  .element {
    min-width: 0;
  }

  .floating-button {
    display: block;
  }

  .button--large {
    font-size: $fontSizeLarge;
  }
}

.menu-enter-active,
.menu-leave-active {
  transition: opacity 0.5s;
}
.menu-enter, .menu-leave-to /* .menu-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100px, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 1s;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(100%, 0);
}
</style>
